import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './CategoryCarousel.css';

const CategoryCarousel: React.FC = () => {
  const [rotation, setRotation] = useState(0); // Tracks the current rotation

  const handleItemClick = (index: number) => {
    setRotation(index * -40); // Rotate by -40 degrees for each item
  };

  return (
    <div className="carousel-container">
      <div
        className="carousel"
        style={{ transform: `rotateY(${rotation}deg)` }} // Apply rotation on click
      >
        <button className="homeS" onClick={() => handleItemClick(0)}>
          <Link to="/home" aria-label="Fruits and Vegetables">
            <img src="/images/FruitVeges.png" alt="Fruits and Vegetables" className="static-icon" />
            <p>Fruits and Vegetables</p>
          </Link>
        </button>

        <button className="homeS" onClick={() => handleItemClick(1)}>
          <Link to="/natural-products" aria-label="Natural Products">
            <img src="/images/natural.png" alt="Natural Products" className="static-icon" />
            <p>Natural Products</p>
          </Link>
        </button>
        
        <button className="homeS" onClick={() => handleItemClick(1)}>
          <Link to="/natural-products" aria-label="Natural Products">
            <img src="/images/natural.png" alt="Natural Products" className="static-icon" />
            <p> Products</p>
          </Link>
        </button>

        <button className="homeS" onClick={() => handleItemClick(1)}>
          <Link to="/natural-products" aria-label="Natural Products">
            <img src="/images/natural.png" alt="Natural Products" className="static-icon" />
            <p> Products</p>
          </Link>
        </button>
        <button className="homeS" onClick={() => handleItemClick(1)}>
          <Link to="/natural-products" aria-label="Natural Products">
            <img src="/images/natural.png" alt="Natural Products" className="static-icon" />
            <p> Products</p>
          </Link>
        </button>

        {/* Add more buttons/cards as needed */}
      </div>
    </div>
  );
};

export default CategoryCarousel;
