import React, { useState } from 'react';
import './Home.css';
import './VegetableCard.css';

interface Vegetable {
  VegetableID: number;
  Name: string;
  Price: number;
  Quantity: number;
  ImgUrl: string;
  Unit: string;
  AllowsHalfKg: boolean;
  canToggleUnits: boolean;
  isOutOfStock: boolean; 
}

interface VegetableCardProps {
  vegetable: Vegetable;
  onAddToCart: (vegetable: Vegetable, isKg: boolean, priceInKg: number) => void;
  onInputChange: (vegetableName: string, newValue: string) => void;
  inputValue: string;
  isSearchResult?: boolean;
}

const VegetableCard: React.FC<VegetableCardProps> = ({
  vegetable,
  onAddToCart,
  onInputChange,
  inputValue,
  isSearchResult,
}) => {
  console.log(`VegetableCard.tsx - ${vegetable.Name}: isOutOfStock = ${vegetable.isOutOfStock}`);

  const [isKg, setIsKg] = useState(vegetable.Unit === 'kg');
  const [isUnitsButtonActive, setIsUnitsButtonActive] = useState(!isKg);
  const [isKgButtonActive, setIsKgButtonActive] = useState(isKg);
  const cardClassName = isSearchResult ? 'veGisDivs searchResult' : 'veGisDivs';

  const toggleToKg = () => {
    setIsKg(true);
    setIsKgButtonActive(true);
    setIsUnitsButtonActive(false);
  };

  const toggleToUnits = () => {
    setIsKg(false);
    setIsKgButtonActive(false);
    setIsUnitsButtonActive(true);
  };

  const calculatePrice = () => {
    if (vegetable.Unit === 'bag') {
      const quantity = parseFloat(inputValue);
      const pricePerKg = vegetable.Price / vegetable.Quantity;
      const price = pricePerKg * quantity;
      return Number(price.toFixed(2));
    } else {
      const pricePerUnit = isKg ? vegetable.Price : vegetable.Price / 6;
      const quantity = parseFloat(inputValue);
      const price = pricePerUnit * quantity;
      return Number(price.toFixed(2));
    }
  };

  const incrementQuantity = () => {
    const newValue =
      vegetable.AllowsHalfKg && isKg
        ? (parseFloat(inputValue) + 0.5).toFixed(1)
        : (parseFloat(inputValue) + 1).toString();
    onInputChange(vegetable.Name, newValue);
  };

  const decrementQuantity = () => {
    const newValue =
      vegetable.AllowsHalfKg && isKg
        ? Math.max(0, parseFloat(inputValue) - 0.5).toFixed(1)
        : Math.max(0, parseFloat(inputValue) - 1).toString();
    onInputChange(vegetable.Name, newValue);
  };

  const handleAddToCart = () => {
    if (vegetable.isOutOfStock) {
      alert(`${vegetable.Name} is out of stock`);
      return;
    }
    if (vegetable.Unit === 'bag') {
      const priceInKg = calculatePrice();
      onAddToCart(vegetable, true, priceInKg);
    } else {
      onAddToCart(vegetable, isKg, calculatePrice());
    }
  };

  return (
    <div className={`${cardClassName}`}>
      <div className="imgContainer">
        <img
          className={`vegetablesImgs ${vegetable.isOutOfStock ? 'disabled-img' : ''}`}
          src={vegetable.ImgUrl}
          alt={vegetable.Name}
        />
        {vegetable.isOutOfStock && (
          <div className="out-of-stock-overlay">
     
          </div>
        )}
      </div>
      <div className="h3Divs">
        <h3 className="vegetableName">{vegetable.Name}</h3>
      </div>
      <div className="pDiv">
        {vegetable.Unit === 'bag' ? (
          <p>מארז: ₪ {vegetable.Price.toFixed(2)}</p>
        ) : (
          <p>ק"ג: ₪ {vegetable.Price.toFixed(2)}</p>
        )}
      </div>



      <div className="buttonsDiv">
        <div className="addToCartDiv">
          <button
            className="addToCartButton"
            onClick={handleAddToCart}
            disabled={vegetable.isOutOfStock}
          >
            {vegetable.isOutOfStock ? 'אזל מהמלאי' : 'הוסף לסל'}
          </button>
        </div>
        <div className="AddRemoveDiv">
          <button
            className="quantity-btn"
            onClick={decrementQuantity}
            disabled={vegetable.isOutOfStock}
          >
            -
          </button>
          <span className="spanNum">{inputValue}</span>
          <button
            className="quantity-btn"
            onClick={incrementQuantity}
            disabled={vegetable.isOutOfStock}
          >
            +
          </button>
        </div>
        {vegetable.canToggleUnits && (
          <div className="toggleButtons">
            <button
              className={isUnitsButtonActive ? 'toggle-button active' : 'toggle-button'}
              onClick={toggleToUnits}
              disabled={vegetable.isOutOfStock}
            >
              יח
            </button>
            <button
              className={isKgButtonActive ? 'toggle-button-second active' : 'toggle-button-second'}
              onClick={toggleToKg}
              disabled={vegetable.isOutOfStock}
            >
              ק"ג
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VegetableCard;