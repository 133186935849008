// NaturalProducts.tsx
import React, { useState, useEffect } from 'react';
import VegetableCard from './VegetableCard';
import './NaturalProducts.css'; // Optional CSS file for styling

interface Vegetable {
  VegetableID: number;
  Name: string;
  Price: number;
  Quantity: number;
  ImgUrl: string;
  Unit: string;
  AllowsHalfKg: boolean;
  canToggleUnits: boolean;
  isOutOfStock: boolean;
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const NaturalProducts: React.FC = () => {
  const [vegetables, setVegetables] = useState<Vegetable[]>([]);
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    fetchNaturalProducts();
  }, []);

  const fetchNaturalProducts = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/natural-products`);
      if (!response.ok) {
        throw new Error('Failed to fetch natural products');
      }
      const data = await response.json();
      console.log('Fetched natural products:', data);

      // Adjust property names if necessary
      const adjustedData = data.map((item: any) => ({
        VegetableID: item.VegetableID,
        Name: item.Name,
        Price: item.Price,
        Quantity: item.Quantity,
        ImgUrl: item.ImgUrl,
        Unit: item.Unit,
        AllowsHalfKg: item.AllowsHalfKg,
        canToggleUnits: item.CanToggleUnits,
        isOutOfStock: item.IsOutOfStock,
      }));

      setVegetables(adjustedData);

      // Initialize input values for each product
      const initialInputValues: { [key: string]: string } = {};
      adjustedData.forEach((veg: Vegetable) => {
        initialInputValues[veg.Name] = '1';
      });
      setInputValues(initialInputValues);
    } catch (error) {
      console.error('Error fetching natural products:', error);
    }
  };

  const handleAddToCart = (vegetable: Vegetable, isKg: boolean, priceInKg: number) => {
    // Implement your cart logic here
    console.log('Added to cart:', vegetable.Name, 'Quantity:', inputValues[vegetable.Name]);
  };

  const handleInputChange = (vegetableName: string, newValue: string) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [vegetableName]: newValue,
    }));
  };

  return (
    <div className="natural-products">
      <h2>Natural Products</h2>
      <div className="product-list">
        {vegetables.map((vegetable) => (
          <VegetableCard
            key={vegetable.VegetableID}
            vegetable={vegetable}
            onAddToCart={handleAddToCart}
            onInputChange={handleInputChange}
            inputValue={inputValues[vegetable.Name] || '1'}
          />
        ))}
      </div>
    </div>
  );
};

export default NaturalProducts;
