import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import VegetableCard from './VegetableCard';
import { useAuth } from './AuthProvider';
import Register from './Register';
import { useCart } from './CartProvider'; // Import Cart context
import { availableAreas } from './availableAreas';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

interface Vegetable {
  VegetableID: number;
  Name: string;
  Price: number;
  Quantity: number;
  ImgUrl: string;
  Unit: string;
  AllowsHalfKg: boolean;
  canToggleUnits: boolean;
  isOutOfStock:boolean;
}

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { currentUser, logout } = useAuth();
  const { handleAddToCart, inputValues, setInputValues, cartQuantities } = useCart(); // Get cart-related functions from CartContext
  const isAdmin = currentUser?.email === 'admin@example.com';
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Vegetable[]>([]);
  const [vegetables, setVegetables] = useState<Vegetable[]>([]);
  const [showRegister, setShowRegister] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [address, setAddress] = useState('');
  const [showAccessibilityMenu, setShowAccessibilityMenu] = useState(false);

  const handleCloseSearchResults = () => {
    setSearchResults([]);
    setSearchQuery('');
  };

  useEffect(() => {
    fetchVegetables();
  }, []);

  const fetchVegetables = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/vegetables`);
      if (!response.ok) {
        throw new Error('Failed to fetch vegetables');
      }
      const data = await response.json();
      const updatedData = data.map((vegetable: Vegetable) => ({
        ...vegetable,
        ImgUrl: `${IMAGE_BASE_URL}/${vegetable.ImgUrl}`,
      }));
      setVegetables(updatedData);
  
      const initialInputValues: { [key: string]: string } = {};
      updatedData.forEach((vegetable: Vegetable) => {
        initialInputValues[vegetable.Name] = '0';
      });
      setInputValues(initialInputValues);
    } catch (error) {
      console.error('Error fetching vegetables:', error);
    }
  };

  const handleSearch = () => {
    if (searchQuery.trim() !== '') {
      const filtered = vegetables.filter((vegetable) =>
        vegetable.Name && vegetable.Name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setSearchResults(filtered);
    } else {
      setSearchResults([]);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleInputChange = (vegetableName: string, newValue: string) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [vegetableName]: newValue,
    }));
  };

  const handleAddToCartClick = (vegetable: Vegetable, isKg: boolean) => {
    handleAddToCart(vegetable, isKg);
  };

  const handleCheckService = () => {
    const available = availableAreas.some((area) => address.includes(area));
    setShowPopup(false);
    if (available) {
      alert('Service is available in your area.');
    } else {
      alert('Service not available in your area.');
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/home');
  };

  const handleCloseRegister = () => {
    setShowRegister(false);
  };

  const toggleAccessibilityMenu = () => {
    setShowAccessibilityMenu(!showAccessibilityMenu);
  };

  const adjustTextSize = (size: string) => {
    document.documentElement.style.fontSize = size;
  };

  const toggleHighContrast = () => {
    document.body.classList.toggle('high-contrast');
  };

  return (
    <header className='HeaderDiv' role="banner" style={{ backgroundImage: `url('${IMAGE_BASE_URL}/GGp.png')` }}>
      <nav aria-label="Main navigation">
        <div className='BBLinksDiv'>
          <button className='homeB' onClick={() => setShowRegister(true)} aria-label="Account">
            <span className="link-text">חשבון</span>
          </button>



          {currentUser && (
            <button className='homeB'><Link to='/welcome' aria-label={`Welcome, ${currentUser.name}`}>Welcome, {currentUser.name}</Link></button>
          )}
          {isAdmin && (
            <button className='homeB'><Link to='/VegetableManager' aria-label="Manager">מנהל</Link></button>
          )}
          {currentUser && (
            <button className='homeB' onClick={handleLogout} aria-label="Logout">Logout</button>
          )}
        </div>
      </nav>

      <div className='SearchDiv'>
        <input
          id="search"
          type="text"
          placeholder='חפש מוצר'
          className='SearchBar'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyPress={handleKeyPress}
          aria-label="Search"
        />
        <button className='SearchB' onClick={handleSearch} aria-label="Search button">
          חיפוש
        </button>
      </div>
      <div className='logoDiv'>
        <img src={`${IMAGE_BASE_URL}/logo.png`} alt="Logo" className="LogoImg" />
      </div>
      <div className='whatsupDIV'>
        <a className="whatsup" href="https://api.whatsapp.com/send?phone=0542567775" aria-label="Customer Service">
          שירות לקוחות
          <img className="whatsupicon" src={`${IMAGE_BASE_URL}/WhatsApp_icon.png`} alt="WhatsApp Icon" />
        </a>
      </div>
      <div className="searchResults">
        {searchResults.length > 0 && (
          <div className="searchResultsHeader">
            <button onClick={handleCloseSearchResults} aria-label="Close search results">X</button>
          </div>
        )}



        {searchResults.map((vegetable, index) => (
          <VegetableCard
            key={index}
            vegetable={vegetable}
            onAddToCart={(veg: Vegetable, isKg: boolean) => handleAddToCartClick(veg, isKg)}
            onInputChange={(vegName: string, newValue: string) => handleInputChange(vegName, newValue)}
            inputValue={inputValues[vegetable.Name] || '0'}
            isSearchResult={true}
          />
        ))}
      </div>

      {showRegister && (
        <Register
          onLoginSuccess={handleCloseRegister}
          onClose={handleCloseRegister}
        />
      )}

      {showPopup && (
        <div className="popup" role="dialog" aria-labelledby="popupTitle" aria-describedby="popupDesc">
          <h3 id="popupTitle">Check if we are available in your area</h3>
          <label htmlFor="address" id="popupDesc" className="sr-only">Enter your address</label>
          <input
            id="address"
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Enter your address"
            aria-label="Enter your address"
          />
          <button onClick={handleCheckService} aria-label="Check service availability">Check</button>
        </div>
      )}

      <button className="accessibilityButton" onClick={toggleAccessibilityMenu} aria-label="Accessibility options">
        נגישות
      </button>
      {showAccessibilityMenu && (
        <div className="accessibilityMenu" role="menu">
          <button onClick={() => adjustTextSize('16px')} aria-label="Small text">A-</button>
          <button onClick={() => adjustTextSize('18px')} aria-label="Default text">A</button>
          <button onClick={() => adjustTextSize('20px')} aria-label="Large text">A+</button>
          <button onClick={() => adjustTextSize('22px')} aria-label="Large text">A++</button>

          <button onClick={toggleHighContrast} aria-label="Toggle high contrast">High Contrast</button>
        </div>
      )}
    </header>
  );
};

export default Header;
