import React, { useState, useEffect } from 'react';
import './Home.css';
import VegetableCard from './VegetableCard';
import Register from './Register';
import PaymentForm from './PaymentForm';
import { useAuth } from './AuthProvider';
import { useCart } from './CartProvider';

interface Vegetable {
  VegetableID: number;
  Name: string;
  Price: number;
  Quantity: number;
  ImgUrl: string;
  Unit: string;
  AllowsHalfKg: boolean;
  canToggleUnits: boolean;
  isOutOfStock: boolean;
}

interface CustomerDetails {
  Name: string;
  Email: string;
  Phone: string;
  City: string;
  Street: string;
  HouseNumber: string;
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

const Home: React.FC = () => {
  const { currentUser } = useAuth();
  const { cartQuantities, setCartQuantities, inputValues, setInputValues, handleAddToCart } = useCart();  
  const [vegetables, setVegetables] = useState<Vegetable[]>([]);
  const [showCartPopup, setShowCartPopup] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [addError, setAddError] = useState<string | null>(null);
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails | null>(null);
  const [editAddress, setEditAddress] = useState(false);
  const [newAddress, setNewAddress] = useState({
    Street: '',
    HouseNumber: '',
    City: '',
  });

  useEffect(() => {
    fetchVegetables();
    if (currentUser) {
      fetchCustomerDetails(currentUser.id);
    }
  }, [currentUser]);

  const fetchVegetables = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/vegetables`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      const vegetablesWithProperties = data.map((vegetable: any) => ({
        ...vegetable,
        canToggleUnits: vegetable.canToggleUnits || vegetable.CanToggleUnits || false,
        isOutOfStock: vegetable.isOutOfStock || vegetable.IsOutOfStock || false,
        }));

      setVegetables(vegetablesWithProperties);

      const initialInputValues: { [name: string]: string } = {};
      vegetablesWithProperties.forEach((vegetable: Vegetable) => {
        initialInputValues[vegetable.Name] = '0';
      });
      setInputValues(initialInputValues);
    } catch (error) {
      console.error('Error fetching vegetables:', error);
    }
  };

  const fetchCustomerDetails = async (userID: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/customers/details/${userID}`);
      if (!response.ok) {
        throw new Error('Failed to fetch customer details');
      }
      const data = await response.json();
      setCustomerDetails(data);
    } catch (error) {
      console.error('Error fetching customer details:', error);
    }
  };

  const handleInputChange = (vegetableName: string, newValue: string) => {
    const newQuantity = parseFloat(newValue);
    if (!isNaN(newQuantity)) {
      const updatedValue = Math.max(0, newQuantity).toString();
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [vegetableName]: updatedValue,
      }));
    }
  };

  const handleDeleteCartItem = async (vegetableID: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/cart/${vegetableID}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          UserID: currentUser?.id ? Number(currentUser.id) : undefined,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete item from cart');
      }

      setCartQuantities((prevQuantities) => {
        const updatedQuantities: { [name: string]: { quantity: number; isKg: boolean; price: number } } = { ...prevQuantities };
        const vegetableToDelete = vegetables.find((veg) => veg.VegetableID === vegetableID);

        if (vegetableToDelete) {
          delete updatedQuantities[vegetableToDelete.Name];
        }

        return updatedQuantities;
      });
    } catch (error) {
      console.error('Error deleting item from cart:', error);
      setAddError('Failed to delete item from cart. Please try again.');
    }
  };

  const handleContinueToPay = () => {
    if (!currentUser) {
      setShowRegister(true);
    } else {
      setShowCartPopup(true);
    }
  };

  const handleCloseRegister = () => {
    setShowRegister(false);
  };

  const handleCloseCartPopup = () => {
    setShowCartPopup(false);
    setShowPaymentForm(true);
  };

  const handleIncrementQuantity = (vegetableName: string) => {
    setCartQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };
      if (updatedQuantities[vegetableName]) {
        updatedQuantities[vegetableName].quantity += 1;
      }
      return updatedQuantities;
    });
  };

  const handleDecrementQuantity = (vegetableName: string) => {
    setCartQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };
      if (updatedQuantities[vegetableName]) {
        updatedQuantities[vegetableName].quantity = Math.max(0, updatedQuantities[vegetableName].quantity - 1);
      }
      return updatedQuantities;
    });
  };

  const handleEditAddress = () => {
    setEditAddress(true);
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleSaveAddress = () => {
    setEditAddress(false);
  };

  const calculateTotalPrice = () => {
    return Object.keys(cartQuantities)
      .reduce((total, vegetableName) => {
        const { quantity, isKg, price } = cartQuantities[vegetableName];
        const vegetable = vegetables.find((v) => v.Name === vegetableName);

        if (!vegetable || vegetable.isOutOfStock) return total;

        const itemPrice = isKg
          ? price * quantity
          : vegetable.Unit === 'bag'
          ? (price / vegetable.Quantity) * quantity
          : (price / 2.26) * quantity;

        return total + itemPrice;
      }, 0)
      .toFixed(2);
  };

  const handlePaymentSubmit = (formData: any) => {
    console.log('Payment form submitted:', formData);
    setShowPaymentForm(false);
  };

  const handlePaymentClose = () => {
    setShowPaymentForm(false);
  };

  // Remove out-of-stock items from the cart and notify the user
  useEffect(() => {
    const outOfStockItems = Object.keys(cartQuantities).filter((vegetableName) => {
      const vegetable = vegetables.find((v) => v.Name === vegetableName);
      return vegetable && vegetable.isOutOfStock;
    });

    if (outOfStockItems.length > 0) {
      setAddError(`The following items are now out of stock and have been removed from your cart: ${outOfStockItems.join(', ')}`);
      outOfStockItems.forEach((vegetableName) => {
        const vegetable = vegetables.find((v) => v.Name === vegetableName);
        if (vegetable) {
          handleDeleteCartItem(vegetable.VegetableID);
        }
      });
    }
  }, [vegetables]);

  return (
    <div className="bigDiv">
      {vegetables.map((vegetable: Vegetable, index: number) => {
  console.log(`Home.tsx - ${vegetable.Name}: isOutOfStock = ${vegetable.isOutOfStock}`);
  return (
    <VegetableCard
    key={index}
    vegetable={{
      ...vegetable,
      ImgUrl: `${IMAGE_BASE_URL}/${encodeURIComponent(vegetable.Name)}.png`,
    }}
    onAddToCart={(veg: Vegetable, isKg: boolean) => {
      if (veg.isOutOfStock) {
        setAddError(`${veg.Name} is currently out of stock.`);
        return;
      }
      handleAddToCart(veg, isKg);
    }}
    onInputChange={(name, value) => setInputValues({ ...inputValues, [name]: value })}
    inputValue={inputValues[vegetable.Name] || '0'}
  />
);
})}


      {addError && <p className="error">{addError}</p>}

      <div className="CartDiv" style={{ backgroundImage: `url('${IMAGE_BASE_URL}/woodenbox2.png')` }}>
        <div className="HeaderCart">
          {Object.keys(cartQuantities).length > 0 && (
            <>
              <button className="totalPrice">₪ סך הכל: {calculateTotalPrice()}</button>
              <button className="continueToPayBtn" onClick={handleContinueToPay}>
                המשך לתשלום
              </button>
            </>
          )}
        </div>

        <div className="wrapDiv">
          {Object.keys(cartQuantities).length === 0 ? (
            <div className="emptyCart">
              <p className="PPCart">העגלה שלך ריקה כרגע הוסף כמה פריטים כדי להתחיל</p>
              <img className="cartimg" src={`${IMAGE_BASE_URL}/עגלה2.webp`} alt="Cart is empty" />
            </div>
          ) : (
            Object.keys(cartQuantities).map((vegetableName, index) => {
              const { quantity, isKg } = cartQuantities[vegetableName];
              const vegetable = vegetables.find((v) => v.Name === vegetableName);

              if (!vegetable || vegetable.isOutOfStock) {
                // Skip out-of-stock items
                return null;
              }

              const unitLabel = vegetable.Unit === 'bag' ? 'מארז' : isKg ? 'ק"ג' : 'יחידות';
              const pricePerUnit =
                vegetable.Unit === 'bag' ? vegetable.Price / vegetable.Quantity : isKg ? vegetable.Price : vegetable.Price / 2.26;

              return (
                <div className="CartItems" key={index}>
                  <div className="cartDetails">
                    <img
                      className="cartItemImage"
                      src={`${IMAGE_BASE_URL}/${encodeURIComponent(vegetableName)}.png`}
                      alt={vegetableName}
                    />
                    <div className="itemDetails">
                      <p className="itemQuantity" style={{ marginRight: '5px', fontSize: '20px' }}>
                        כמות {quantity}
                      </p>
                      <p className="itemPrice" style={{ marginRight: '5px', fontSize: '20px' }}>
                        ₪ {pricePerUnit.toFixed(2)}
                      </p>
                      <p className="itemName" style={{ marginRight: '5px', fontSize: '20px' }}>
                        {vegetableName}
                      </p>
                      <p className="itemUnit" style={{ marginRight: '5px', fontSize: '20px' }}>
                        מחיר ל - {unitLabel}
                      </p>
                      <button className="quantity-btn" onClick={() => handleDecrementQuantity(vegetableName)}>
                        ‒
                      </button>
                      <button className="quantity-btn" onClick={() => handleIncrementQuantity(vegetableName)}>
                        +
                      </button>
                    </div>
                    <button className="CartDeleteB" onClick={() => handleDeleteCartItem(vegetable.VegetableID)}>
                      &#10006;
                    </button>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>

      {showRegister && <Register onLoginSuccess={handleCloseRegister} onClose={handleCloseRegister} />}

      {showCartPopup && (
        <div className="cart-popup">
          <div className="cart-popup-content">
            <div className="popup-header">
              <button className="continueToCreditCard" onClick={handleCloseCartPopup}>
                המשך למילוי פרטי אשראי
              </button>
              <h1 className="summery">סיכום הזמנה</h1>
              <button className="close-popup-btn" onClick={() => setShowCartPopup(false)}>
                ❌
              </button>
            </div>
            <div className="popupContent">
              <div className="orderDetails">
                <ul>
                  {Object.keys(cartQuantities).map((vegetableName, index) => {
                    const { quantity, isKg } = cartQuantities[vegetableName];
                    const vegetable = vegetables.find((v) => v.Name === vegetableName);
                    if (!vegetable || vegetable.isOutOfStock) {
                      // Skip out-of-stock items
                      return null;
                    }
                    const unitLabel = vegetable.Unit === 'bag' ? 'מארז' : isKg ? 'ק"ג' : 'יחידות';
                    const pricePerUnit =
                      vegetable.Unit === 'bag' ? vegetable.Price / vegetable.Quantity : isKg ? vegetable.Price : vegetable.Price / 2.26;
                    return (
                      <div className="CartItems" key={index}>
                        <div className="cartDetails">
                          <img
                            className="cartItemImages"
                            src={`${IMAGE_BASE_URL}/${encodeURIComponent(vegetableName)}.png`}
                            alt={vegetableName}
                          />
                          <div className="itemDetails">
                            <span className="itemUnit" style={{ marginRight: '8px' }}>
                              מחיר ל - {unitLabel}
                            </span>
                            <strong className="itemName" style={{ marginRight: '8px' }}>
                              {vegetableName}
                            </strong>
                            <span className="itemPrice" style={{ marginRight: '8px' }}>
                              ₪ {pricePerUnit.toFixed(2)}
                            </span>
                            <span className="itemQuantity" style={{ marginRight: '8px' }}>
                              כמות {quantity}
                            </span>
                          </div>
                          <button className="quantity-btn" onClick={() => handleDecrementQuantity(vegetableName)}>
                            ‒
                          </button>
                          <button className="quantity-btn" onClick={() => handleIncrementQuantity(vegetableName)}>
                            +
                          </button>
                          <button className="CartDeleteB" onClick={() => handleDeleteCartItem(vegetable.VegetableID)}>
                            &#10006;
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </ul>
              </div>
              <div className="invoiceDetails">
                <div className="logoDivvv">
                  <img src={`${IMAGE_BASE_URL}/logo.png`} alt="Logo" className="LogoImgsss" />
                  <h2>חשבונית</h2>
                </div>

                {customerDetails && (
                  <div>
                    <div className="infoItem">
                      <span>{customerDetails.Name}</span>
                      <span>:שם מלא</span>
                    </div>
                    <div className="infoItem">
                      <span>{customerDetails.Email}</span>
                      <span>:כתובת מייל</span>
                    </div>
                    <div className="infoItem">
                      <span>{customerDetails.Phone}</span>
                      <span>:טלפון</span>
                    </div>
                    <p>המשלוח צפוי להגיע ליעד בתוך 3 ימי עסקים</p>
                    <div className="infoItem">
                      <span>
                        {editAddress ? (
                          <div className="editAddressForm">
                            <input
                              type="text"
                              name="Street"
                              value={newAddress.Street}
                              onChange={handleAddressChange}
                              placeholder="רחוב"
                            />
                            <input
                              type="text"
                              name="HouseNumber"
                              value={newAddress.HouseNumber}
                              onChange={handleAddressChange}
                              placeholder="מספר בית"
                            />
                            <input
                              type="text"
                              name="City"
                              value={newAddress.City}
                              onChange={handleAddressChange}
                              placeholder="עיר"
                            />
                            <button onClick={handleSaveAddress}>שמור</button>
                          </div>
                        ) : (
                          <>
                            {newAddress.Street || customerDetails.Street} {newAddress.HouseNumber || customerDetails.HouseNumber}
                            , {newAddress.City || customerDetails.City}
                            <button onClick={handleEditAddress}> ✏️ </button>
                          </>
                        )}
                      </span>
                      <span>:כתובת למשלוח</span>
                    </div>
                    <div className="summary">
                      <div className="infoItem">
                        <span>₪ {calculateTotalPrice()}</span>
                        <span>:עלות כוללת</span>
                      </div>
                      <div className="infoItem">
                        <span>₪ 26</span>
                        <span>:עלות משלוח</span>
                      </div>
                      <div className="infoItem">
                        <span>₪ {(parseFloat(calculateTotalPrice()) + 26).toFixed(2)}</span>
                        <span>:סה"כ סופי</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showPaymentForm && (
        <div className="payment-popup">
          <div className="payment-popup-content">
            <PaymentForm onSubmit={handlePaymentSubmit} onClose={handlePaymentClose} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
