// VegetableManager.tsx
import React, { useState, useEffect } from 'react';
import './VegetableManager.css'; // Import the CSS file

interface Vegetable {
  VegetableID: number;
  Name: string;
  Price: number;
  Quantity: number;
  ImgUrl: string;
  Unit: string;
  AllowsHalfKg: boolean;
  canToggleUnits: boolean;
  isOutOfStock: boolean; // Field to manage stock status
  CategoryID: number; // Include CategoryID
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const VegetableManager: React.FC = () => {
  const [vegetables, setVegetables] = useState<Vegetable[]>([]);
  const [formData, setFormData] = useState({
    Name: '',
    Price: '',
    Quantity: '',
    ImgUrl: '',
    Unit: 'kg', // Default unit
    CategoryID: '', // Include CategoryID in formData
    canToggleUnits: false, // Include canToggleUnits in formData
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchVegetables();
  }, []);

  const fetchVegetables = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/vegetables`);
      if (!response.ok) {
        throw new Error('Failed to fetch vegetables');
      }
      const data = await response.json();
      console.log('Fetched vegetables:', data); // Debugging log

      // Map over data to adjust property names
      const adjustedData = data.map((veg: any) => ({
        VegetableID: veg.VegetableID,
        Name: veg.Name,
        Price: veg.Price,
        Quantity: veg.Quantity,
        ImgUrl: veg.ImgUrl,
        Unit: veg.Unit,
        AllowsHalfKg: veg.AllowsHalfKg,
        canToggleUnits: veg.CanToggleUnits, // Map CanToggleUnits to canToggleUnits
        isOutOfStock: veg.IsOutOfStock, // Map IsOutOfStock to isOutOfStock
        CategoryID: veg.CategoryID, // Corrected from product.CategoryID to veg.CategoryID
      }));

      setVegetables(adjustedData);
    } catch (error) {
      console.error('Error fetching vegetables:', error);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const validateForm = () => {
    if (
      !formData.Name ||
      !formData.Price ||
      !formData.Quantity ||
      !formData.CategoryID ||
      !formData.ImgUrl
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) {
      setErrorMessage('All fields are required');
      return;
    }
    setErrorMessage(null);
    try {
      const response = await fetch(`${API_BASE_URL}/vegetables`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Name: formData.Name,
          Price: parseFloat(formData.Price),
          Quantity: parseInt(formData.Quantity),
          CategoryID: parseInt(formData.CategoryID),
          ImgUrl: formData.ImgUrl,
          Unit: formData.Unit, // Ensure Unit is set correctly based on user selection
          canToggleUnits: formData.canToggleUnits,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to add vegetable');
      }
      const newVegetable = await response.json();
      console.log('New vegetable added:', newVegetable);
      setFormData({
        Name: '',
        Price: '',
        Quantity: '',
        CategoryID: '',
        ImgUrl: '',
        Unit: 'kg', // Reset to default after submission
        canToggleUnits: false,
      });
      setSuccessMessage('Vegetable added successfully');
      fetchVegetables();
    } catch (error) {
      console.error('Error adding vegetable:', error);
      setErrorMessage('Error adding vegetable');
    }
  };

  const handleDelete = async (vegetableId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/vegetables/${vegetableId}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Failed to delete vegetable');
      }
      fetchVegetables();
    } catch (error) {
      console.error('Error deleting vegetable:', error);
    }
  };

  const handleToggleStock = async (vegetableId: number, currentStockStatus: boolean) => {
    try {
      const response = await fetch(`${API_BASE_URL}/vegetables/${vegetableId}/toggle-stock`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          isOutOfStock: !currentStockStatus, // Toggle the stock status
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to update stock status');
      }
      const updatedVegetable = await response.json();
      console.log('Updated vegetable:', updatedVegetable);

      // Adjust property names if necessary
      const adjustedVegetable = {
        ...updatedVegetable,
        isOutOfStock: updatedVegetable.IsOutOfStock, // Map IsOutOfStock to isOutOfStock
        canToggleUnits: updatedVegetable.CanToggleUnits,
        // Map other properties as needed
      };

      // Update the local state
      setVegetables((prevVegetables) =>
        prevVegetables.map((veg) =>
          veg.VegetableID === vegetableId
            ? { ...veg, isOutOfStock: adjustedVegetable.isOutOfStock }
            : veg
        )
      );

      setSuccessMessage('Vegetable stock status updated successfully');
    } catch (error) {
      setErrorMessage('Error updating stock status');
      console.error('Error updating stock status:', error);
    }
  };

  return (
    <div className="vegetable-manager">
      <h2>Vegetable Manager</h2>
      <form onSubmit={handleSubmit} className="vegetable-form">
        <div className="form-row">
          <input
            type="text"
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            placeholder="Name"
          />
          <input
            type="number"
            step="0.01"
            name="Price"
            value={formData.Price}
            onChange={handleChange}
            placeholder="Price"
          />
          <input
            type="text"
            name="Quantity"
            value={formData.Quantity}
            onChange={handleChange}
            placeholder="Quantity"
          />
          <input
            type="text"
            name="CategoryID"
            value={formData.CategoryID}
            onChange={handleChange}
            placeholder="Category ID"
          />
          <input
            type="text"
            name="ImgUrl"
            value={formData.ImgUrl}
            onChange={handleChange}
            placeholder="Image URL"
          />
          <select name="Unit" value={formData.Unit} onChange={handleChange}>
            <option value="kg">לקילו</option>
            <option value="bag">לשקית</option>
          </select>
          <label className="toggle-units">
            Allow Toggle Units:
            <input
              type="checkbox"
              name="canToggleUnits"
              checked={formData.canToggleUnits}
              onChange={handleChange}
            />
          </label>
        </div>
        <button type="submit">Add Vegetable</button>
      </form>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      <ul className="vegetable-list">
        {vegetables.map((vegetable: Vegetable) => (
          <li
            key={vegetable.VegetableID}
            className={`vegetable-item ${vegetable.isOutOfStock ? 'out-of-stock' : ''}`}
          >
            {vegetable.Name} - {vegetable.Price.toFixed(2)} - {vegetable.Quantity} -{' '}
            {vegetable.Unit}
            <button
              onClick={() => handleToggleStock(vegetable.VegetableID, vegetable.isOutOfStock)}
            >
              {vegetable.isOutOfStock ? 'Mark as In Stock' : 'Mark as Out of Stock'}
            </button>
            <button onClick={() => handleDelete(vegetable.VegetableID)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VegetableManager;
